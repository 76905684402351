
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { setPageHistory } from '@/core/helpers/toolbar';
import { Apollo, Notify } from '@/core/services';
import { GET_PRODUCTS, SEARCH_PRODUCTS } from '../graphql/Queries';
import { DELETE_PRODUCT } from '../graphql/Mutations';
import AdvanceSearch from '../../../../components/search/AdvanceSearch.vue';
import { useRouter } from 'vue-router';
import Table from '../../../../components/Table/Table.vue';
import { GET_CATEGORIES_DROPDOWN_LIST } from '../../categories/graphql/Queries';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';

export default defineComponent({
    components: {
        AdvanceSearch,
        Table,
        InnerLoader
    },
    setup() {
        const i18n = useI18n();
        const store = useStore();
        const loader = ref(false);
        const loading = ref(false);
        const productsData: any = ref([]);
        const pagination = ref({}) as Record<any, any>;
        const systemLocale = ref();
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const permissions = store.getters.getPermissions;
        const router = useRouter();
        const filterOption = ref({}) as Record<any, any>;
        const sites = ref([]) as Record<any, any>;
        const categoriesList = ref([]) as Record<any, any>;
        const filterCategoriesList = ref([]) as Record<any, any>;
        const activeIndex = ref(-1);
        const search_product_query = ref(false);
        const columnName = ref(null);
        const columnSort = ref(null);

        filterOption.value = {
            status: false,
            sites: false,
            category: true,
            base_info: true
        };

        const statusList = ref([
            {
                label: 'message.ALL',
                value: 'all'
            },
            {
                label: 'message.ACTIVE',
                value: 1
            },
            {
                label: 'message.INACTIVE',
                value: 0
            }
        ]);
        filterOption.value.status = true;

        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name',
                sorting: true
            },
            {
                label: 'message.PRODUCT_NUMBER',
                key: 'economic_number',
                sorting: true
            },
            {
                label: 'message.QUANTITY',
                key: 'available_stock',
                sorting: true
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const filterObject = ref([
            {
                label: 'message.NAME',
                value: 'name'
            },
            {
                label: 'message.PRODUCT_NUMBER',
                value: 'model'
            }
        ]);

        watchEffect(() => {
            systemLocale.value = i18n.fallbackLocale.value;
            sites.value = [];
            categoriesList.value = [];
            filterCategoriesList.value.forEach(ele => {
                categoriesList.value.push({
                    label: JSON.parse(ele.name)[`${systemLocale.value}`].name,
                    value: ele.id
                });
            });
            const getSites = store.getters.getAllSites.data.sites;
            getSites.forEach((element, index) => {
                sites.value.push({
                    label: element.name,
                    value: element.id
                });
            });
            filterOption.value.sites = true;
        });

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const getCategories = () => {
            search_product_query.value = false;
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_CATEGORIES_DROPDOWN_LIST,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                filterCategoriesList.value = [];
                filterCategoriesList.value = data.categories?.data;
                // loader.value = false;
                filterOption.value.category = true;
            });
        };

        const getProducts = (page = 0, reload = false, colName = null, type = null) => {
            // if (colName != null) columnName.value = colName;
            // if (type != null) columnSort.value = type;
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            Apollo.watchQuery({
                query: GET_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    // col: colName,
                    // type: type == true ? 'asc' : 'desc',
                    locale: systemLocale.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = getProducts;
                productsData.value = data.products.data;
                pagination.value = data.products;
                loader.value = false;
            });
        };

        const loadCategory = () => {
            if (categoriesList.value.length == 0) {
                getCategories();
            }
        };

        const handleCurrentChange = (num: number) => {
            clickHandler.value(num);
        };

        const handleEdit = (product: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });
            router.push({ name: 'edit-product', params: { uuid: product.uuid } });
        };

        const searchHandler = (page = 0, reload = false, colName = null, type = null) => {
            search_product_query.value = true;
            if (colName != null) columnName.value = colName;
            if (type != null) columnSort.value = type;
            const searchData = store.getters.getSearchData;
            loader.value = true;
            Apollo.watchQuery({
                query: SEARCH_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    filter: searchData?.data?.select != null ? JSON.stringify(searchData?.data?.select) : '',
                    search_key: searchData?.data?.input,
                    locale: systemLocale.value,
                    col: columnName.value,
                    type: columnSort.value == true ? 'asc' : 'desc'
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                clickHandler.value = searchHandler;
                productsData.value = data.search_products.data;
                pagination.value = data.search_products;
                loader.value = false;
            });
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_PRODUCT,
                        variables: { id: id },
                        update: (store, { data: { delete_product } }) => {
                            // const read_data = store.readQuery({
                            // 	query: GET_PRODUCTS,
                            // 	variables: {
                            // 		page: 0,
                            // 		limit: 10,
                            // 	},
                            // }) as Record<any, any>;
                            // const data = read_data.products.data.filter((t: any) => t.id !== delete_product);
                            //
                            // store.writeQuery({
                            // 	query: GET_PRODUCTS,
                            // 	data: {
                            // 		products: {
                            // 			...read_data.products,
                            // 			data: [...data],
                            // 		},
                            // 	},
                            // 	variables: {
                            // 		page: 0,
                            // 		limit: 10,
                            // 	},
                            // });
                            searchHandler(pagination.value?.current_page, true, columnName.value, columnSort.value);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(e => {
                    console.log(e);
                    loader.value = false;
                });
        };

        const exportFile = data => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_PRODUCTS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: searchData?.data?.select ? JSON.stringify(searchData?.data?.select) : '',
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    locale: systemLocale.value,
                    types: data?.type,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_products?.data[0]?.path, '_blank');
                    loading.value = false;
                }
                loading.value = false;
                sub.unsubscribe();
            });
        };

        onMounted(() => {
            const pageHistory = store.getters.getPageHistory;
            document.addEventListener('click', handleClickOutside);
            if (pageHistory?.filter != undefined || pageHistory?.search_key != undefined) {
                searchHandler(pageHistory.page_number);
            } else {
                pageHistory.page_number == undefined ? getProducts(0, true) : getProducts(pageHistory.page_number);
            }
        });

        return {
            systemLocale,
            pagination,
            productsData,
            filterObject,
            currentPage,
            permissions,
            columns,
            filterOption,
            sites,
            statusList,
            categoriesList,
            loader,
            loading,
            exportFile,
            getProducts,
            searchHandler,
            loadCategory,
            handleEdit,
            handleDelete,
            handleCurrentChange,
            activeIndex,
            dropdownHandler,
            handleClickOutside
        };
    }
});
