
import {setPageHeader} from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import ProductsList from "@/modules/catalog/products/components/ProductsList.vue";

export default defineComponent({
  name: "products",
  components: {
    ProductsList,
  },
  setup() {
    onMounted(() => {

        setPageHeader({
            title: "message.PRODUCTS",
            actionButton : {
                ability: "add_product",
                pageAction: {
                    action: "AddProductAction",
                },
                button: {
                    icon: "bi bi-plus",
                    openModal: false,
                    path: "/catalog/add-product",
                }
            },
            breadCrumbs: [
                { name: "message.HOME", link: "/dashboard"},
                { name: "message.CATALOG", link: "#"},
                { name: "message.PRODUCTS"}
            ]
        });
    });
  },
});
